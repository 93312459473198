.base-video .video-react button.video-react-big-play-button {
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 45px);
}

div.card-controls div.ui.inline.dropdown.course-menu-dropdown {
  display:flex;
  flex-grow: 1;
  align-items: center;
}

div.card-controls div.course-menu-dropdown>div.divider.text {
  flex-grow: 1;
}

.courses-tabs .ui.secondary.menu .active.item {
  background-color: #eff3db;
}