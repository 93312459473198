.assessment-answers .ui.basic.segment {
  padding: 0;
}

.assessment-answers .ui.basic.segment .ui.accordion .content .ui.info.message .content {
  padding: 0;
}

.assessment-answers .ui.vertical.menu {
  position: sticky;
  top: 10px;
}

.assessment-answers .ui.vertical.menu .item {
  font-weight: bold;
}

.assessment-answers .ui.vertical.menu .item>i.icon {
  float: left;
  margin: 0 .5em 0 0;
}

.assessment-answers .ui.vertical.menu .item.active {
  background-color: #eff3db!important;
}