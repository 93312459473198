.date-range-input .react-datepicker-wrapper {
  width: 100%;
}

.date-range-input .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.date-range-input .react-datepicker-popper {
  z-index: 5;
}

.date-range-input.ui.grid>.column:first-child {
  padding-right: .5rem;
}
.date-range-input.ui.grid>.column:last-child {
  padding-left: .5rem;
}

.ui.form .field.error .date-range-input .labeled.input .basic.label {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}