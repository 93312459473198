div.main-content-container {
  display: block;
  max-width: 100%!important;
}

@media only screen and (min-width: 1800px) {
  .sidebar-container.open div.main-content-container {
    width: 1400px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

@media only screen and (max-width: 1799px) and (min-width: 1500px) {
  .sidebar-container.open div.main-content-container {
    width: 1160px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

@media only screen and (min-width: 1500px) {
  .sidebar-container div.main-content-container {
    width: 1400px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

@media only screen and (max-width: 1200px) {
  div.main-content-container {
      width: auto!important;
      margin-left: auto!important;
      margin-right: auto!important;
  }
}