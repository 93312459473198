table.compact-messages.ui.selectable.striped.red.table {
  opacity: .95;
}

table.compact-messages.ui.selectable.striped.red.table tr.pointer {
  cursor: pointer;
}

table.compact-messages.ui.selectable.striped.red.table tr.pointer td.collapsing {
  white-space: normal;
}