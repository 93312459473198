.course-answers:not(:last-child) {
  margin-bottom: 15px;
}

.course-answers .ui.segment {
  padding: .5em;
}

.course-answers .ui.header {
  margin-top: 0;
}

.course-answers .controls {
  float: right;
}

.course-answers .ui.selection.dropdown.attempt-selector {
  width: 300px;
  line-height: .65em;
  min-height: 1em;
  display: inline-block;
  margin-right: 10px;
}

.course-answers .ui.button {
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}