.baseline-home {
  background-image: url("https://course.base.education/img/teen-with-backpack-on-top-of-mountain.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  min-height: 100vh;
}


.baseline-home .ui.menu.top-nav {
  position: sticky;
  width: 100%;
  border-radius: 0;
  top: 0px;
  margin: 0;
  z-index: 99;
}

.baseline-home .ui.menu .logo {
  padding-bottom: 10px;
  padding-top: 10px;
}
