.ui.segment.remote-search-segment {
  padding: 0;
}
.ui.selection.list.remote-search-list {
  overflow-y: auto;
}

.ui.selection.list.remote-search-list .item {
  padding: 1em;
}

.ui.selection.list.remote-search-list .item:hover,
.ui.selection.list.remote-search-list .item:focus {
  outline: none;
  background-color: #eff3db;
  cursor: pointer;
}