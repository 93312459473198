@keyframes zoom {
  0% {
    font-size: 1.6em;
  }
  50% {
    font-size: 1.4em;
  }
  100% {
    font-size: 1.8em;
  }
}

.sidebar-new .sidebar-top .ui.header.sidebar-header {
  font-size: 1.2em;
  position: absolute;
  padding: 9px 10px 0px 10px;
  height: 40px;
  max-width: calc(100% - 40px);
  margin: 0px;
  opacity: 0;
  color: white;
  transition: opacity .2s ease-in-out;
}

.sidebar-container.open .sidebar-new .ui.header.sidebar-header {
  opacity: 1;
}

.sidebar-new  .sidebar-trigger {
  width: 20px;
}

.sidebar-new .sidebar-trigger:after, 
.sidebar-new .sidebar-trigger:before, 
.sidebar-new .sidebar-trigger div {
  background-color: white;
  border-radius: 3px;
  content: '';
  display: block;
  height: 3px;
  margin: 3px 0;
  width: 100%;
  transition: all .2s ease-in-out;
}

.sidebar-new .sidebar-container {
  display: flex;
  min-height: 100vh;
}

.sidebar-new {
  position: fixed;
  top: 0;
  overflow: hidden;
  transition: all .2s ease-in-out;
  height: 100%;
  background-color: #ffffff;
  width: 40px;
  left: 0;
  box-shadow: -4px 0px 8px #282c33;
}

.sidebar-new .ui.button.sidebar-trigger-container {
  position: absolute;
  height: 100%;
  /* water */
  /* background-color: #4A8B9A; */
  background-color: #335973;
  border-radius: 0;
  margin: 0 10px 0 0;
  padding: 0;
  top: 0px;
  right: 0px;
}

.sidebar-new .sidebar-logo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #dddddd;
  height: 65px;
}

.sidebar-new .sidebar-logo .ui.image.closed-sidebar-logo {
  width: 32px;
  margin: 8px auto;
}

.sidebar-new .sidebar-logo .ui.image.open-sidebar-logo {
  display: none;
  width: 110px;
  margin: 6px auto;
}

.sidebar-new .ui.button.sidebar-trigger-container:focus,
.sidebar-new .ui.button.sidebar-trigger-container:hover {
  /* water */
  /* background-color: #4A8B9A; */
  background-color: #335973;
}

.sidebar-new .ui.button.sidebar-trigger-container:focus .sidebar-trigger:before,
.sidebar-new .ui.button.sidebar-trigger-container:focus .sidebar-trigger:after,
.sidebar-new .ui.button.sidebar-trigger-container:focus .sidebar-trigger div,
.sidebar-new .ui.button.sidebar-trigger-container:hover .sidebar-trigger:before,
.sidebar-new .ui.button.sidebar-trigger-container:hover .sidebar-trigger:after,
.sidebar-new .ui.button.sidebar-trigger-container:hover .sidebar-trigger div {
  background-color: white;
}

/* .sidebar-new .sidebar-top .ui.button.sidebar-focus-button {
  margin-top: 8px;
  margin-right: 40px;
  padding: 6px;
  width: 30px;
  margin-left: 0;
} */

.sidebar-container .sidebar-new .sidebar-menu-container {
  width: 100%;
  height: 100%;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu {
  padding: 10px 0 0 10px;
  height: calc(100% - 90px);
  overflow-y: hidden;
  overflow-x: hidden;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .sidebar-main-menu-top {
  padding-right: 10px;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.menu {
  margin: 0;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.menu .item {
  margin: 0;
  font-weight: bold;
  padding-right: 0;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.menu .item>i.icon {
  width: 1.18em;
  float: none;
  margin: 0 4px 0 0;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.menu .item.active {
  background-color: #eff3db;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion.no-baseline,
.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion.no-rooms {
  height: calc(100% - 252px);
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion.no-baseline.no-rooms {
  height: calc(100% - 216px);
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion {
  height: calc(100% - 288px);
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion>.item {
  height: 100%;
  padding-bottom: 0;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion>.item>.content {
  height: calc(100% - 24px);
  margin-right: 0;
  padding-top: 0;
}

.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion .item .title {
  padding-right: 10px;
  padding-bottom: 10px;
}
.sidebar-container .sidebar-new .sidebar-menu-container .sidebar-main-menu .ui.accordion .item .title .dropdown.icon {
  font-size: 1.3em;
  margin-top: -2px;
}

.sidebar-container .sidebar-new .sidebar-menu-container .org-placeholder {
  text-align: center;
  margin-top: 50%;
}

.sidebar-container .sidebar-new .sidebar-menu {
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.sidebar-container .sidebar-new .sidebar-menu .show-more-container {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.sidebar-container .sidebar-new .sidebar-search {
  display: flex;
  margin: 0px 10px 10px 0px;
  height: 30px;
}

.sidebar-container .sidebar-new .sidebar-search.collapsed {
  display: flex;
  margin: 0;
  height: 10px;
}

.sidebar-container .sidebar-new .sidebar-search .ui.input {
  flex-grow: 1;
}

.sidebar-container .sidebar-new .sidebar-search .sidebar-focus-button {
  margin-left: 10px;
}

.sidebar-container .sidebar-new .sidebar-search .ui.input>input{
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 1;
}

.main-content {
  transition: all .3s ease-in-out;
  margin-top: 15px;
  margin-left: 55px;
  width: calc(100% - 70px);
}

.sidebar-new .organizations-list {
  margin: 0px 0px 10px 0px;
}

.sidebar-new .ui.buttons.mini-buttons {
  margin: 0;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button {
  border-radius: 0;
  border-top: none;
  background-color: #fff;
  padding: .25em .25em 0 .25em;
  height: 40px;
  color: #888888;
  transition: color .2s ease-in-out;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button.active {
  background-color: #eff3db;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button i {
  font-size: 1.6em;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button:first-child {
  border-top: 1px solid #dddddd;
  border-radius: 0;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button:last-child {
  border-radius: 0;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button span {
  display: none;
}

.sidebar-new .ui.buttons.mini-buttons .ui.button:hover,
.sidebar-new .ui.buttons.mini-buttons .ui.button:focus {
  color: #222222;
}

/* .ui.buttons.mini-buttons .ui.button:hover i,
.ui.buttons.mini-buttons .ui.button:focus i {
  animation: zoom ease-in-out 0.2s 1 normal forwards;
} */

.sidebar-container .sidebar-new .sidebar-top {
  /* water */
  /* background-color: #4A8B9A; */
  background-color: #335973;
  height: 40px;
  position: absolute;
  top: 0px;
  bottom: 40px;
  width: 100%;
}

.sidebar-container .sidebar-new hr {
  margin-top: 40px;
  margin-bottom: 0;
  clear: both;
  border-top: 1px solid #dddddd;
  border-right: none;
  border-bottom: none;
  border-left: none;
}


/* Sidebar Open: */
.sidebar-container.open > .sidebar-new {
  width: 300px;
}

.sidebar-container.open .sidebar-new .sidebar-trigger:before {
  transform: translateY(6px) rotate(135deg);
}
.sidebar-container.open .sidebar-new .sidebar-trigger:after {
  transform: translateY(-6px) rotate(-135deg);
}
.sidebar-container.open .sidebar-new .sidebar-trigger > div {
  transform: scale(0);
}

.sidebar-container.open > .sidebar-new > .ui.button.sidebar-trigger {
  padding-right: calc(300px - 40px);
}

.sidebar-container.open > .main-content {
  margin-left: 315px;
  width: calc(100% - 330px);
}

.sidebar-container.open .sidebar-new .sidebar-logo .ui.image.closed-sidebar-logo {
  display: none;
}

.sidebar-container.open .sidebar-new .sidebar-logo .ui.image.open-sidebar-logo {
  display: block;
}

.sidebar-container.open .sidebar-new .sidebar-menu {
  display: block;
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
}

.sidebar-container.open .sidebar-new .ui.buttons.mini-buttons .ui.button span {
  display: inline;
}

.sidebar-container.open .sidebar-new .ui.buttons.mini-buttons .ui.button {
  border-top: 1px solid #dddddd;
  flex-basis: 0;
  height: 60px;
}