.ui.modal.messageModal>.content {
  font-size: 1.2em;
}

.messageModal .ui.button.baseGreen {
  background-color: rgb(0, 189, 156);
	color: white;
}

.messageModal .ui.button.baseBlue {
  background-color: rgb(56, 179, 231);
	color: white;
}
