.content-item .ui.dropdown.content-item-input .menu>.item>img {
  max-height: 6em;
}

.ui.small.images .image.bg-image-select {
  cursor: pointer;
  transition: outline .1s ease-in-out;
}

.ui.form.content-item-form textarea.content-item-input {
  resize: none;
}
