.ui.form.user-profile-form.warning {
  border-color: #f2711a;
  border-width: 1px;
}

.ui.form.user-profile-form .ui.label.float-right {
  float: right;
}

.ui.form.user-profile-form .ui.header.inline {
  display: inline-block;
}