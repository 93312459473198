.ui.segment.room-details {
  margin: 0;
}

.rooms-grid {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rooms-grid>div {
  width: 49%;
}

@media only screen and (max-width: 1200px) {
  .rooms-grid {
    flex-direction: column;
  }
  .rooms-grid>div {
    width: 100%;
  }
  .rooms-grid>div:last-child {
    margin-top: 1em;
  }
}


.ui.cards .ui.card.room-card>.image>img {
  height: 155px;
  object-fit: cover;
}

.ui.segment.room-details .ui.header>img {
  float: left;
  width: 90px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}

.ui.list.requested-students-list>.item {
  padding-left: 10px;
}

.ui.list.requested-students-list>.item:hover {
  border-left: 5px solid #0e566c;
  padding-left: 5px;
}

.ui.list.requested-students-list>.item>.content {
  display: flex;
  align-items: center;
}

.ui.list.requested-students-list>.item>.content>span {
  flex-grow: 2;
}

.ui.divided.selection.list.remote-search-list>.item.new-student {
  background-color: rgb(217, 245, 200);
}

.ui.segment.room-details .menu .active.item {
  background-color: #eff3db;
}