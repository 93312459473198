.userActivity {
  z-index: 1;
}

.userActivity .right.floated.column {
  text-align: right;
}

.userActivity .right.floated.column .ui.dropdown .menu {
  right: 0;
  left: auto;
}

.userActivity .ui.header.activityHeader {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

.userActivity .ui.segment {
  opacity: .95;
}

.userActivity .ui.button.downloadButton {
  margin-left: 15px;
}