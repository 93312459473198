.ui.form.client-account-form .field.checkbox {
  margin-top: 2em;
}

.ui.form.client-account-form .field.override-checkbox {
  float: right;
}

.ui.form.client-account-form.warning {
  border-color: #f2711a;
  border-width: 1px;
}

.ui.form.client-account-form .ui.label.float-right {
  float: right;
}

.ui.form.client-account-form .ui.header.inline {
  display: inline-block;
}