.ui.basic.buttons .ui.button:hover,
.ui.basic.buttons .ui.button:focus {
  background-color: #eee!important;
}

.ui.basic.buttons .ui.button.pack-custom.active,
.ui.basic.buttons .ui.button.pack-custom.active:hover,
.ui.basic.buttons .ui.button.pack-custom.active:focus {
  background-color: #339CFF!important;
  color: #fff!important;
  text-shadow: none!important;
}

.ui.basic.buttons .ui.button.pack-on.active,
.ui.basic.buttons .ui.button.pack-on.active:hover,
.ui.basic.buttons .ui.button.pack-on.active:focus {
  background-color: #4D9965!important;
  color: #fff!important;
  text-shadow: none!important;
}

.ui.basic.buttons .ui.button.pack-off.active,
.ui.basic.buttons .ui.button.pack-off.active:hover,
.ui.basic.buttons .ui.button.pack-off.active:focus {
  background-color: #CC3366!important;
  color: #fff!important;
  text-shadow: none!important;
}
