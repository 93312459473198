.ui.form.access-schedule-form .ui.grid>.column .ui.big.label {
  font-size: 1.2rem;
}

.ui.form.access-schedule-form.warning {
  border-color: #f2711a;
  border-width: 1px;
}

.ui.form.access-schedule-form .ui.label.float-right {
  float: right;
}

.ui.form.access-schedule-form .ui.header.inline {
  display: inline-block;
}