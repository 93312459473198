.ui.checkbox.checkbox-group {
  padding-right: 10px;
}

.ui.checkbox.checkbox-group-vertical {
  display: block;
  padding-bottom: 10px;
}

.ui.checkbox.checkbox-group label,
.ui.checkbox.checkbox-group-vertical label {
  display: inline-block;
}

.ui.checkbox.checkbox-group label .subLabel,
.ui.checkbox.checkbox-group-vertical label .subLabel {
  display: block;
  font-style: italic;
  color: gray;
}