.fireword-segment {
  overflow: hidden;
  margin-top: 10px;
  border: solid 1px hsl(3, 93%, 78%);
  border-radius: 5px;
  font-weight: bold;
  background-color: hsl(3, 93%, 78%);
}

.fireword-segment.orange {
  border: solid 1px hsl(36, 87%, 60%);
  background-color: hsl(36, 87%, 60%);
}

.fireword-segment.yellow {
  border: solid 1px hsl(49, 93%, 78%);
  background-color: hsl(49, 93%, 78%);
}

.fireword-segment.red.reviewed {
  /* border: solid 1px hsl(3, 93%, 78%); */

  border: solid 1px hsl(3, 43%, 80%);
  background-color: hsl(3, 43%, 80%);
}

.fireword-segment.orange.reviewed {
  /* border: solid 1px hsl(36, 87%, 60%); */
  border: solid 1px hsl(36, 57%, 80%);
  background-color: hsl(36, 57%, 80%);
}

.fireword-segment.yellow.reviewed {
  /* border: solid 1px hsl(49, 93%, 78%); */

  border: solid 1px hsl(49, 63%, 88%);
  background-color: hsl(49, 63%, 88%);
}

.fireword-segment.reviewed {
  border: solid 1px hsl(191, 43%, 72%);
  background-color: hsl(191, 43%, 72%);
}

.fireword-segment .segment-content {
  background-color: hsl(0, 100%, 93%);
  font-weight: normal;
  padding: 10px;
}

.fireword-segment.orange .segment-content {
  background-color: #fdf5ec;
}

.fireword-segment.yellow .segment-content {
  background-color: #fffae3;
}

.fireword-segment .segment-content ul {
  padding-left: 30px;
}
.fireword-segment.reviewed .segment-content {
  background-color: hsl(0, 50%, 98%);
  font-weight: normal;
  padding: 10px;
}

.fireword-segment.orange.reviewed .segment-content {
  background-color: hsl(32, 41%, 96%);
}

.fireword-segment.yellow.reviewed .segment-content {
  background-color: hsl(49, 50%, 95%);
}

.fireword-segment .segment-title {
  padding: 10px;

}
.fireword-segment .segment-title .ui.label {
  margin-right: 8px;
}

.fireword-segment .segment-title .safe-text {
  float: right;
}

.fireword-segment .ui.star.rating .icon.selected {
  background: 0 0!important;
  color: #444!important;
  text-shadow: none!important;
}

.fireword-segment .ui.star.rating .active.icon {
  background: 0 0!important;
  color: #000!important;
  text-shadow: none!important;
}