.reset-enrollment-modal .selection-button-group {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.reset-enrollment-modal .selection-button {
  width: 45%;
}

.reset-enrollment-modal .selection-button .icon.header {
  margin: 0;
}

.reset-enrollment-modal .divider {
  font-size: 1.2rem;
  font-weight: bold;
  align-self: center;
  padding: 0 30px;
}

.reset-enrollment-modal .selection-button .ui.segment {
  background-color: rgba(255, 255, 255, .95);
  cursor: pointer;
}

.reset-enrollment-modal .selection-button:hover .ui.segment,
.reset-enrollment-modal .selection-button:focus .ui.segment {
  background-color: rgba(245, 245, 245, 0.95);
}

.reset-enrollment-modal .selection-button:focus .ui.segment {
  box-shadow: 0 0 2px 1px rgba(24, 175, 245, 0.582);
}

.reset-enrollment-modal .selection-button:hover,
.reset-enrollment-modal .selection-button:focus {
  outline: none;
}