.base-form-container {
  margin: 1rem 0;
}

.ui.segment.base-form.warning {
  border-color: #f2711a;
}

.ui.segment.base-form.basic {
  padding: 0;
}

.ui.segment.base-form .ui.label.float-right {
  float: right;
  margin: 0;
}

.ui.segment.base-form .ui.header.inline {
  display: inline-block;
}