.create-room-modal .ui.segment.room-courses-selection {
  padding: 0;
}

.create-room-modal .ui.selection.list.room-courses-list .item {
  padding: 1em;
}

.create-room-modal .ui.selection.list.room-courses-list .item:hover,
.create-room-modal .ui.selection.list.room-courses-list .item:focus,
.create-room-modal .ui.selection.list.remote-search-list .item:hover,
.create-room-modal .ui.selection.list.remote-search-list .item:focus  {
  outline: none;
  background-color: #eff3db;
  cursor: pointer;
}

.create-room-modal .ui.header.selected-room-items-header {
  margin-top: .3em;
}

.create-room-modal .ui.segment.selected-room-items {
  padding: 0;
}

.create-room-modal .ui.segment.selected-room-items .ui.list .item {
  padding: 1em;
}

#room-image-input .menu>.item>img {
  width: 45px;
  height: 35px;
  max-height: 35px;
  object-fit: cover;
}

.ui.modal.create-room-modal {
  width: 90%;
  position: absolute;
  top: 1em;
  bottom: 1em;
}
.ui.modal.create-room-modal>.content {
  position: absolute;
  top: 1em;
  bottom: 65px;
  left: 0;
  right: 0;
  width: auto;
  padding: 0 1em!important;
  overflow-y: auto!important;
}
.ui.modal.create-room-modal>.content>.steps,
.ui.modal.create-room-modal>.content>.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}
@media only screen and (max-width: 700px) {
  .ui.modal.create-room-modal>.content>.steps .step .icon {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .ui.modal.create-room-modal>.content>.steps .step .description {
    padding: 1rem 0 0 0!important;
  }
}

.ui.modal.create-room-modal>.content>.scrollable {
  margin-top: 1em;
  padding: 0 1em;
  height: 80%;
  overflow-y: auto;
}

.ui.modal.create-room-modal>.actions {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.create-clients-form .button-frame {
  display: flex;
  margin: 25px 0px 0px 0px;
  justify-content: flex-end;
}