.course-card {
  transition: all .3s ease;
  /* cursor: pointer;  */
}

.course-card .card-controls .control {
  display: flex;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  border: 0;
  width: 100%;
}

.course-card .card-controls .control:hover {
  background-color: #f5f5f5;
}
.course-card .card-controls .control:focus {
  background-color: #4A8B9A;
  color: white!important;
  outline: none;
}

.course-card .card-controls .control .icon {
  margin: 0;
  min-width: 25px;
}

.course-card img {
  height: 155px;
  object-fit: cover;
}

.course-card hr {
  border: 0; 
  height: 1px; 
  background-image: linear-gradient(90deg, #f0f0f0, #bdbdbd, #f0f0f0);
}

.course-card .ui.dimmer {
  border-radius: inherit;
  background-color: rgba(42,100,127,.72);
}

.course-card .ui.dimmer .loader:before {
  border: .5em solid rgba(0,0,0,.1)!important;
}

.course-card .ui.dimmer .loader:after {
  border-width: .5em!important;
  animation-duration: 1.2s;
}

.course-card .ui.progress {
  margin: -1.7em 0 0 0;
  border-radius: 0px;
  background: rgba(0,0,0,.4);
}

.course-card .ui.progress .bar {
  border-radius: 0px;
  min-width: 2.4em;
}

.course-card .ui.active.progress .bar::after {
  animation: none;
}

.course-card .ui.inline.dropdown>.text {
  font-weight: unset;
  font-family: sans-serif;
}
