.ui.form.put-organization-form {
  margin-top: 10px;
}

.ui.form.put-organization-form.warning {
  border-color: #f2711a;
  border-width: 1px;
}

.ui.form.put-organization-form .ui.label.float-right {
  float: right;
}

.ui.form.put-organization-form .ui.header.inline {
  display: inline-block;
}

.ui.modal.org-type-modal .ui.segment {
  cursor: pointer;
}

.ui.modal.org-type-modal .ui.segment:hover {
  background-color: #f7f7f7;
}

.ui.modal.org-type-modal .ui.segment.selected {
  background-color: #f7f7f7;
}

.ui.modal.org-type-modal .modal-content {
  margin: 15px;
}

.ui.modal.org-type-modal .modal-content .modal-segment-content {
  display: flex;
}

.ui.modal.org-type-modal .modal-content .modal-segment-content .ui.header {
  margin-bottom: 0;
  flex-grow: 2;
  padding-right: 10px;
}