.whatsNewModal img {
  max-width: 800px;
}

.ui.menu .right.item.old-nav-link {
  font-size: 0.75rem;
}

.org-name-header {
  /* background-color: rgba(0,0,0,0.5); */
  padding: 0px 10px 0px 0px;
  /* border-radius: 5px; */
}

div.new-org-page-header {
  display: flex;
  align-items: center;
}

div.new-org-page-header .ui.button.top-button {
  font-size: 1.3rem;
  flex-shrink: 2;
  margin-left: 10px;
  margin-right: 0;
  background-color: white;
  color: #666;
  border-color: #666;
  border-width: 2px;
  border-style: solid;
  padding: .45em !important;
}

div.new-org-page-header .ui.button.top-button>i {
  cursor: pointer !important;
}