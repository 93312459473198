.home-page .ui.menu.top-nav {
  position: sticky;
  width: 100%;
  border-radius: 0;
  top: 0px;
  margin: 0;
  z-index: 99;
}

.home-page .ui.menu .logo {
  padding-bottom: 10px;
  padding-top: 10px;
}

.home-page .right.menu .ui.dropdown .menu {
  left: auto;
  right: 0;
}

.home-page .top-nav-menu {
  padding: 0;
}
