.progress-bar {
  background: rgba(235,235,235,1);
  border: 1px solid rgba(39,41,43,.15);
  border-radius: .2857rem;
  padding: .2857em;
  width: 100%;
}

.progress-bar .progress-bar-progress {
  background: #4D9965;
  border: 1px solid #4D9965;
  border-radius: .2857rem;
  min-width: 30px;
  transition: width 200ms;
  height: 20px;
  color: white;
  text-align: right;
  padding-right: 3px;
  font-size: .8em;
}

.progress-bar .progress-bar-progress.hide-text {
  min-width: 0px;
}
