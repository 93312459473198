/* semantic-ui colors */
.text.red {
  color: #db2828!important;
}
.text.orange {
  color: #f2711c!important;
}
.text.yellow {
  color: #fbbd08!important;
}
.text.olive {
  color: #b5cc18!important;
}
.text.green {
  color: #21ba45!important;
}
.text.teal {
  color: #00b5ad!important;
}
.text.blue {
  color: #2185d0!important;
}
.text.purple {
  color: #a333c8!important;
}
.text.violet {
  color: #6435c9!important;
}
.text.pink {
  color: #e03997!important;
}
.text.brown {
  color: #a5673f!important;
}
.text.grey {
  color: #767676!important;
}
.text.light-grey {
  color: #aaaaaa!important;
}
.text.white {
  color: #ffffff!important;
}
.text.black {
  color: #000000!important;
}

.base-teal {
  /* base-teal changed to water */
  /* color: #4A8B9A!important; */
  color: #335973!important;
}
.base-green {
  /* changed to emerald */
  /* color: #8ea70b!important; */
  color: #4D9965!important;
}
.base-red {
  /* changed to lotus */
  /* color: #B74B29!important; */
  color: #CC3366!important;
}
.base-blue {
  /* changed to sky */
  /* color: #2F8CCA!important; */
  color: #339CFF!important;
}
.base-purple {
  color: #735EA1!important;
}
.base-teal-border {
  /* changed to water */
  /* border-color: #4A8B9A!important; */
  border-color: #335973!important;
}
.base-green-border {
  /* changed to emerald */
  /* border-color: #8ea70b!important; */
  border-color: #4D9965!important;
}
.base-red-border {
  /* changed to lotus */
  /* border-color: #B74B29!important; */
  border-color: #CC3366!important;
}
.base-blue-border {
  /* changed to sky */
  /* border-color: #2F8CCA!important; */
  border-color: #339CFF!important;
}
.base-purple-border {
  border-color: #735EA1!important;
}
.seven-m-green-border {
  /* changed to emerald */
  /* border-color: #85c446!important; */
  border-color: #70AD85!important;
}
.base-teal-bg {
  /* water */
  /* background-color: #4A8B9A!important; */
  background-color: #335973!important;
}
.base-green-bg {
  /* emerald */
  /* background-color: #8ea70b!important; */
  background-color: #4D9965!important;
}
.base-light-green-bg {
  /* emerald lightest shade */
  /* background-color: #eff3db!important; */
  background-color: #D8FBF0!important;
}
.base-red-bg {
  /* lotus */
  /* background-color: #B74B29!important; */
  background-color: #CC3366!important;
}
.base-blue-bg {
  /* sky */
  /* background-color: #2F8CCA!important; */
  background-color: #339CFF!important;
}
.base-purple-bg {
  background-color: #735EA1!important;
}

body .rtl {
  direction: rtl;
  text-align: right!important;
}

body .ui.toggle.checkbox input:checked~.box:before,
body .ui.toggle.checkbox input:checked~label:before,
body .ui.toggle.checkbox input:focus:checked~.box:before,
body .ui.toggle.checkbox input:focus:checked~label:before {
  /* emerald */
  /* background-color: #8ea70b!important; */
  background-color: #4D9965!important;
}

body .ui.breadcrumb a {
    /* water */
    /* color: #4A8B9A; */
    color: #335973;
}

body .ui.attached.info.message,
body .ui.info.message {
  box-shadow: 0 0 0 1px #abcbd2 inset, 0 0 0 0 transparent;
}

body .ui.info.message {
  background-color: #f3f7f6;
  /* water */
  /* color: #4a8b9a; */
  color: #335973;
}
body .ui.info.message .header {
  /* water */
  /* color: #4a8b9a; */
  color: #335973;
}

body .ui.green.button,
body .ui.positive.button {
  /* emerald */
  /* background-color: #8ea70b; */
  background-color: #4D9965;
}

body .ui.green.active.button,
body .ui.green.button:hover,
body .ui.green.button:focus,
body .ui.positive.active.button,
body .ui.positive.button:hover,
body .ui.positive.button:focus {
  /* emerald */
  /* background-color: #8ea70b; */
  background-color: #4D9965;
  filter: brightness(95%);
}

body .ui.green.basic.button:hover,
body .ui.green.basic.button:focus {
  filter: brightness(100%);
  /* emerald */
  /* color: #8ea70b!important;
  border-color: #8ea70b!important;
  box-shadow: 0 0 0 1px #8ea70b inset!important; */
  color: #4D9965!important;
  border-color: #4D9965!important;
  box-shadow: 0 0 0 1px #4D9965 inset!important;
}

body .ui.green.basic.button {
  filter: brightness(85%);
  /* emerald */
  /* color: #8ea70b!important;
  border-color: #8ea70b!important;
  box-shadow: 0 0 0 1px #8ea70b inset!important; */
  color: #4D9965!important;
  border-color: #4D9965!important;
  box-shadow: 0 0 0 1px #4D9965 inset!important;
}

body .ui.blue.button {
  /* water */
  /* background-color: #4a8b9a; */
  background-color: #335973;
}

body .ui.blue.active.button,
body .ui.blue.button:hover,
body .ui.blue.button:focus {
  /* water */
  /* background-color: #4a8b9a; */
  background-color: #335973;
  filter: brightness(95%);
}

body .ui.blue.basic.button:hover,
body .ui.blue.basic.button:focus {
  filter: brightness(100%);
  /* water */
  /* color: #4a8b9a!important;
  border-color: #4a8b9a!important;
  box-shadow: 0 0 0 1px #4a8b9a inset!important; */
  color: #335973!important;
  border-color: #335973!important;
  box-shadow: 0 0 0 1px #335973 inset!important;
}

body .ui.blue.basic.button {
  filter: brightness(85%);
  /* water */
  /* color: #4a8b9a!important;
  border-color: #4a8b9a!important;
  box-shadow: 0 0 0 1px #4a8b9a inset!important; */
  color: #335973!important;
  border-color: #335973!important;
  box-shadow: 0 0 0 1px #335973 inset!important;
}

body .ui.red.button {
  /* lotus */
  /* background-color: #B74B29; */
  background-color: #CC3366;
}

body .ui.red.active.button,
body .ui.red.button:hover,
body .ui.red.button:focus {
  /* lotus */
  /* background-color: #B74B29; */
  background-color: #CC3366;
  filter: brightness(95%);
}

body .ui.red.basic.button:hover,
body .ui.red.basic.button:focus {
  filter: brightness(100%);
  /* lotus */
  /* color: #B74B29!important;
  border-color: #B74B29!important;
  box-shadow: 0 0 0 1px #B74B29 inset!important; */
  color: #CC3366!important;
  border-color: #CC3366!important;
  box-shadow: 0 0 0 1px #CC3366 inset!important;
}

body .ui.red.basic.button {
  filter: brightness(85%);
  /* lotus */
  /* color: #B74B29!important;
  border-color: #B74B29!important;
  box-shadow: 0 0 0 1px #B74B29 inset!important; */
  color: #CC3366!important;
  border-color: #CC3366!important;
  box-shadow: 0 0 0 1px #CC3366 inset!important;
}

body .ui.blue.label {
  /* water */
  /* background-color: #4a8b9a!important;
  border-color: #4a8b9a!important; */
  background-color: #335973!important;
  border-color: #335973!important;
}

body .ui.blue.segment:not(.inverted) {
  /* water */
  /* border-top: 2px solid #4A8B9A!important; */
  border-top: 2px solid #339CFF!important;
}

body .ui.green.segment:not(.inverted) {
  /* emerald */
  /* border-top: 2px solid #8ea70b!important; */
  border-top: 2px solid #4D9965!important;
}

body .ui.red.segment:not(.inverted) {
  /* lotus */
  /* border-top: 2px solid #B74B29!important; */
  border-top: 2px solid #CC3366!important;
}

body .ui.basic.blue.label,
body .ui.basic.blue.labels .label {
  background-color: #fff!important;
  /* water */
  /* border-color: #4A8B9A!important;
  color: #4A8B9A!important; */
  border-color: #335973!important;
  color: #335973!important;
}

body .ui.blue.label,
body .ui.blue.labels .label {
  /* water */
  /* background-color: #4A8B9A!important;
  border-color: #4A8B9A!important; */
  background-color: #335973!important;
  border-color: #335973!important;
  color: #fff!important;
}

body .ui.basic.green.label,
body .ui.basic.green.labels .label {
  background-color: #fff!important;
  /* emerald */
  /* border-color: #8ea70b!important;
  color: #8ea70b!important; */
  border-color: #4D9965!important;
  color: #4D9965!important;
}

body .ui.green.label,
body .ui.green.labels .label {
  /* emerald */
  /* background-color: #8ea70b!important;
  border-color: #8ea70b!important; */
  background-color: #4D9965!important;
  border-color: #4D9965!important;
  color: #fff!important;
}

body .ui.basic.red.label,
body .ui.basic.red.labels .label {
  background-color: #fff!important;
  /* lotus */
  /* border-color: #B74B29!important;
  color: #B74B29!important; */
  border-color: #CC3366!important;
  color: #CC3366!important;
}

body .ui.red.label,
body .ui.red.labels .label {
  /* lotus */
  /* background-color: #B74B29!important;
  border-color: #B74B29!important; */
  background-color: #CC3366!important;
  border-color: #CC3366!important;
  color: #fff!important;
}

body .ui.orange.label,
body .ui.orange.labels .label {
  background-color: #ff9800!important;
  border-color: #ff9800!important;
  color: #fff!important;
}

body .ui.yellow.label,
body .ui.yellow.labels .label {
  background-color: #ffc505!important;
  border-color: #ffc505!important;
  color: #fff!important;
}

.text.normal-weight{
  font-weight: normal!important;
}
.text.bold {
  font-weight: bold!important;
}
.text.italic {
  font-style: italic!important;
}

.text.clip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text.wrap {
  white-space: normal;
}
.text.no-wrap {
  white-space: nowrap;
}

.text.normal {
  font-size: 1rem;
}
.text.large {
  font-size: 1.1rem;
}
.text.larger {
  font-size: 1.2rem;
}
.text.largest {
  font-size: 1.3rem;
}
.text.small {
  font-size: .95rem;
}
.text.smaller {
  font-size: .9rem;
}
.text.smallest {
  font-size: .8rem;
}

.text.rounded-focus-outline:focus {
  outline: none;
  box-shadow: 0px 0px 1pt 1pt;
  border-radius: .3rem;
}

.center-align {
  text-align: center;
}

.middle-align {
  vertical-align: middle;
}

.help {
  cursor: help!important;
}

.pointer {
  cursor: pointer!important;
}

.not-allowed {
  cursor: not-allowed!important;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

form.ui.form > .field > .error.field {
  margin: 0;
}

.ui.segment.transparent {
  background-color: rgba(255,255,255,.9);
}
.ui.fluid.container.ninety {
  width: 90%;
}

i.icon.fas,
i.icon.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.ui.horizontal.divider:before {
  background-position: right 1em top!important;
}
.ui.horizontal.divider:after {
  background-position: left 1em top!important;
}

@media print {
  .no-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  .inline-print-only {
    display: inline !important;
  }
  .no-margin-print {
    margin: 0 !important;
    width: 100% !important;
  }
  .adjust-width-print {
    width: 716px !important;
  }
  .adjust-width-smaller-chart-print {
    width: 375px !important;
  }
}
.print-only, .inline-print-only {
  display: none;
}
