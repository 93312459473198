.ui.items.room-items {
  margin: 0;
}

.ui.items.room-items>.item.room-item.static:hover {
  cursor: unset;
  background-color: unset;
}
.ui.items.room-items>.item.room-item.static {
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.12);
}

.ui.items.room-items>.item.room-item {
  padding: 10px;
  border-radius: 0.5rem;
  position: relative;
}

.ui.items.room-items>.item.room-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.ui.items.room-items>.item.room-item .private-label {
  position: absolute;
  top: 15px;
  left: 15px;
  border: white 2px solid;
  font-size: 1.1em;
}

.ui.items.room-items>.item.room-item .private-label .lock.icon {
  margin: 0 1px;
  color: white;
}

.ui.items.room-items>.item.room-item .image>img {
  height: 100px;
  object-fit: cover;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .ui.items.room-items>.item.room-item .ui.image {
    width: 50px!important;
  }
  .ui.items.room-items>.item.room-item .ui.image>img {
    height: 50px;
  }
  .ui.items.room-items>.item.room-item .private-label {
    font-size: .6em;
  }
  .ui.items.room-items>.item.room-item .private-label .lock.icon {
    margin: 0;
  }
}

@media only screen and (max-width: 850px) and (min-width: 768px) {
  .ui.items.room-items>.item.room-item .ui.image {
    width: 75px;
  }
  .ui.items.room-items>.item.room-item .ui.image>img {
    height: 75px;
  }
  .ui.items.room-items>.item.room-item .private-label {
    font-size: .9em;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 851px) {
  .ui.items.room-items>.item.room-item .ui.image {
    width: 100px;
  }
  .ui.items.room-items>.item.room-item .private-label {
    font-size: .9em;
  }
}

.ui.items.room-items>.item.room-item .description {
  margin-top: 0;
}

.ui.items.room-items>.item.room-item .ui.statistics {
  margin-bottom: 0;
}

.ui.items.room-items>.item.room-item .ui.statistics .ui.statistic:first-child {
  margin-right: 0;
  margin-bottom: 0;
}

.ui.items.room-items>.item.room-item .ui.statistics .ui.statistic {
  margin-top: .6em;
}