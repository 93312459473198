.question-answer-segment {
  background-color: #ebebeb;
  border: solid 1px #ccc;
  border-radius: 5px;
  display: table;
  width: 100%;
}

.question-answer-segment .question-header {
  display: table-cell;
  width: 140px;
  vertical-align: middle;
  padding: 10px;
  border-right: solid 1px #bbb;
  text-align: right;
}

.question-answer-segment .question-header .ui.label {
  margin-right: 8px;
}

.question-answer-segment .question-text {
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  background-color: #f6f6f6;
  border-radius: 0 5px 5px 0;
}

@media print {
  .question-answer-segment {
    background-color: white;
    border: 0;
    display: block;
    width: 100%;
  }

  .question-answer-segment .question-text {
    background-color: white;
    display: table-cell;
    padding: 2px;
    vertical-align: middle;
  }
}