.search-options .ui.grid {
  position: relative;
  z-index: 10;
  margin-top: 0;
}

.search-options .ui.accordion .title {
  display: inline-block;
}

.search-options .ui.accordion .ui.toggle.checkbox.activity-checkbox label {
  color: #9b9b9b;
}

.search-options .ui.accordion .ui.toggle.checked.checkbox.activity-checkbox label {
  color: black;
}

.search-options .ui.inline.dropdown {
  padding-right: 20px;
  padding-left: 6px;
}

.search-options .ui.inline.dropdown .text {
  color: #4A8B9A;
}

.search-options .ui.inline.dropdown .menu .text {
  color: black;
}

.dashboard .ui.grid>.column.no-right-padding {
  padding-right: 0;
}

.dashboard .ui.table .client-item td {
  padding: .8em .8em;
  cursor: pointer;
}

.ui.popup.visible div.course-progress-label {
  margin-top: .2em;
  margin-bottom: 1.5em;
}

i.icon.firewords-red {
  color: #E12C23;
  font-size: 1.5em;
}

i.icon.firewords-orange {
  color: #ff9800;
  font-size: 1.5em;
}

i.icon.firewords-yellow {
  color: #ffc505;
  font-size: 1.5em;
}
 
.firewords.grey {
   color: grey
}

/* semantic modals */
.ui.basic.modal {
  min-height: 250px;
  flex-direction: column;
  display: flex !important;
  background: white !important;
}

.ui.modal.small {
  width: 450px !important;
}

.ui.basic.modal>.header {
  color: black !important;
}

.ui.basic.modal>.content {
  flex: 1 1;
}

.ui.modal>.content>.description {
  width: 100%;
  color: black;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}

.ui.modal>.content>.description .success-label {
  margin-bottom: 20px;
}

.ui.basic.modal>.actions {
  display: flex;
  justify-content: space-between;
}

.ui.password-input {
  width: 100%;
  height: 36px;
  margin: 10px auto 60px auto;
}