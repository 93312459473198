.ui.header.icon.course-usage-chart-icon {
  margin-bottom: 0;
  font-size: .95em;
  cursor: pointer;
}

.ui.header.icon.course-usage-chart-icon:hover {
  color: #555555;
}

.ui.header.icon.course-usage-chart-icon i.icon {
  font-size: 4em;
}