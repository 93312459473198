.staff-help-modal .ui.list {
  display: inline-block;
}
.staff-help-modal .ui.list .item {
  cursor: pointer;
}

.staff-help-modal .ui.list .item .content .header {
  font-size: .9em;
  color: #4A8B9A;
}
.staff-help-modal .ui.list .item .content .description{
  font-size: .75em;
  font-style: italic!important;
}

.staff-help-modal .ui.checkbox {
  display: block;
}