.specific-day-range-access-exception .react-datepicker-wrapper {
  width: 100%;
}

.specific-day-range-access-exception .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.specific-day-range-access-exception .column.warn {
  background-color: #f9e9bb!important;
}

.specific-day-range-access-exception .react-datepicker-popper {
  z-index: 5;
}