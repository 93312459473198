.org-tree {
  font-size: 10px;
}

.org-tree .ui.header.org-header {
  background-color:  white;
  margin-bottom: 5px;
  border: rgba(1,1,1,0) 2px solid;
  border-radius: 5px 25px 25px 5px;
  padding: 1px 0;
}

.org-tree .ui.header.org-header.selected {
  background-color:  #eff3db;
}

.org-tree .ui.header.org-header.selected:hover,
.org-tree .ui.header.org-header.selected:focus {
  background-color:  #eff3db;
}


.org-tree .ui.header.org-header:hover,
.org-tree .ui.header.org-header:focus {
  outline: none;
  background-color: #ededed;
  cursor: pointer;
}

.org-tree .ui.header .content {
  display: table-cell;
  vertical-align: middle;
  padding-left: 2px;
}

.org-tree .ui.header .content .icon {
  margin-right: .25rem;
}

.org-tree .ui.header .content:first-child {
  width: 100%;
}

.org-tree .ui.header .content.active-label .ui.label {
  margin-left: 0;
  margin-right: 6px;
  margin-top: 3px;
}

.org-tree .children {
  margin-left: 10px;
  padding-left: 5px;
  border-left: 2px solid #B74B29;
}