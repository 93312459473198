.ui.container.course-edit {
  margin-top: 10px;
}

.preview-panel .flow {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 50%;
	user-select:none;
}

.preview-panel .flow.padding {
  padding: 50px;
}

.preview-panel .flow.dark-screen {
  background: rgba(0, 0, 0, .5);
}

.preview-panel .flow.dark-screen .checkbox label {
  color: #ffffff !important;
}

.preview-panel .flow.dark-screen h2 {
  color: #ffffff;
}

.preview-panel .flow.dark-screen p {
  color: #ffffff;
}

.preview-panel .flow.dark-screen div {
  color: #ffffff;
}

.preview-panel .flow.light-screen {
  background: rgba(255, 255, 255, .6);
}


.preview-panel .flow .headers h2.large,
.preview-panel .flow .bodies div.large {
  font-size: 200% !important;
  padding: 5px 0;
  margin: 5px 0;
  line-height: 120%;
}

.preview-panel .flow .headers h2.medium,
.preview-panel .flow .bodies div.medium {
  font-size: 150% !important;
  padding: 2px 0;
  margin: 2px 0;
  line-height: 110%;
}
.preview-panel .flow .headers h2.list-item,
.preview-panel .flow .bodies div.list-item {
  display: list-item;
  list-style-position: inside;
}

.preview-panel .flow .headers h2.shadow,
.preview-panel .flow .bodies div.shadow {
  text-shadow: 1px 1px 2px #000;
  color: #ffffff;
}

.preview-panel .flow .headers h2.list-item,
.preview-panel .flow .bodies div.list-item {
  display: list-item;
  list-style-position: inside;
}

.preview-panel .flow .bodies {
  column-count:1;
}
.preview-panel .flow.column.five .bodies {
  column-count: 5;
}
.preview-panel .flow.column.four .bodies {
  column-count: 4;
}
.preview-panel .flow.column.three .bodies {
  column-count: 3;
}
.preview-panel .flow.column.two .bodies {
  column-count: 2;
}







.preview-panel .flow.pull-up {
  top: 0;
  bottom: auto;
}
.preview-panel .flow.pull-up.pull-center {
  left: 50%;
  transform: translateX(-50%);
}

.preview-panel .flow.pull-up.pull-right {
  right: 0;
  left: auto;
}

.preview-panel .flow.pull-up.pull-left {
  left: 0;
}

.preview-panel .flow.pull-up.pull-full-width {
  width: 100%;
}



.preview-panel .flow.pull-vertical,
.preview-panel .flow.vertical-align {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
}

.preview-panel .flow.pull-vertical.pull-center,
.preview-panel .flow.vertical-align.pull-center {
  transform: translateY(-50%) translateX(-50%) !important;
  bottom: auto;
}
.preview-panel .flow.pull-vertical.pull-center,
.preview-panel .flow.vertical-align.pull-center {
  left: 50%;
  transform: translateX(-50%);
}

.preview-panel .flow.pull-vertical.pull-right,
.preview-panel .flow.vertical-align.pull-right {
  right: 0;
  left: auto;
}

.preview-panel .flow.pull-vertical.pull-left,
.preview-panel .flow.vertical-align.pull-left {
  left: 0;
}

.preview-panel .flow.pull-vertical.pull-full-width,
.preview-panel .flow.vertical-align.pull-full-width {
  width: 100%;
}









.preview-panel .flow.pull-down {
  top: auto;
}
.preview-panel .flow.pull-down.pull-center {
  left: 50%;
  transform: translateX(-50%);
}

.preview-panel .flow.pull-down.pull-right {
  right: 0;
  left: auto;
}

.preview-panel .flow.pull-down.pull-left {
  left: 0;
}

.preview-panel .flow.pull-down.pull-full-width {
  width: 100%;
}
