.question-card .question-answer-segment:not(:first-child) {
  margin-top: 10px;
}

.question-card .timestamp {
  float: right;
}

@media print {
  .question-card .question-answer-segment:not(:first-child) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}