.not-found-page div.ui.message {
  top: 100px;
  margin: 0 auto;
  max-width: 600px;
}

.not-found-page {
  background-image: url("https://media.base.education/img/baby-crying.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}