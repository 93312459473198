.slider-view.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.slider {
  display: flex;
  margin-bottom: 15px;
}

.slider-label {
  margin-left: 13px;
}


input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  /* width: 150px; */
  width: 50px;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/*---Chrome, Safari, Opera, Edge Chromium, and FireFox styles---*/

/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba(0,0,0,.05);
  border-radius: 10px;
  height: 20px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;

  /* custom styles */
  /* background-color: #8ea70b; */
  background-color: white;
  border: 1px solid lightgrey;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

input[type="range"]:focus::-webkit-slider-thumb {
  /* border: 1px solid #8ea70b;
  outline: 1px solid #8ea70b; */
  outline-offset: 0.125rem;
}

/*---Firefox styles---*/

/* slider track */
input[type="range"]::-moz-range-track {
  background-color: rgba(0,0,0,.05);
  border-radius: 10px;
  height: 20px;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none;
  /*Removes extra border that FF applies*/
  border-radius: 0;
  /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #5cd5eb;
  height: 2rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #053a5f;
  outline: 3px solid #053a5f;
  outline-offset: 0.125rem;
}