.home-page .ui.menu.top-nav {
  position: sticky;
  width: 100%;
  border-radius: 0;
  top: 0px;
  margin: 0;
  z-index: 99;
}

.home-page .ui.menu .logo {
  padding-bottom: 10px;
  padding-top: 10px;
}

.home-page .right.menu .ui.dropdown .menu {
  left: auto;
  right: 0;
}

.home-page .top-nav-menu {
  padding: 0;
}

.home-page .ui.button.new-home-link {
  background-color: white;
  font-weight: 400;
  font-size: .9rem;
}

.home-page .ui.button.new-home-link:hover,
.home-page .ui.button.new-home-link:focus {
  background-color: white;
}