div.ui.item.organizations-menu {
  border: none;
}

div.ui.item.organizations-menu>div.menu>.divider {
  margin: 0;
}

.ui.menu div.ui.item.organizations-menu>div.menu {
  min-width: 300px;
}

div.ui.inline.loader.active.organizations-menu-loader {
  margin-left: 10px;
}

.organizations-menu.ui.dropdown .menu>.header.organizations-menu-no-orgs {
  padding: 1rem 1rem .75rem;
  margin: 0;
  color: rgba(0,0,0,.5);
  font-size: 1em;
  font-weight: normal;
  text-transform: none;
  font-style: italic;
}

.organizations-menu.ui.dropdown .menu>.item.org-active .description  {
  color: #21ba45;
}
.organizations-menu.ui.dropdown .menu>.item.org-inactive .description  {
  color: #db2828;
}

div.organizations-menu>div.text {
  font-weight: bold;
}