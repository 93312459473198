.set-password-form {
  margin: 0 auto;
  padding: 100px 0 40px 0;
  max-width: 400px;
}

.set-password-page div.ui.message {
  margin: 0 auto;
  max-width: 400px;
}

.set-password-page a {
  cursor: pointer;
}

.set-password-page {
  background-image: url("https://media.base.education/img/teacher-with-students-in-background-male.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  min-height: 100vh;
}