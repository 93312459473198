.enrollment-item .no.activity {
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 130px;
  white-space: normal;
}

.enrollment-item .ui.button.enrollmentAction:first-child {
  margin-top: 0px;
}

.enrollment-item .ui.button.enrollmentAction {
  margin-top: 10px;
}