.courseUsage .right.floated.column {
  text-align: right;
}

.courseUsage .right.floated.column .ui.dropdown .menu {
  right: 0;
  left: auto;
}

.courseUsage .ui.header.usageHeader {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

.courseUsage .ui.segment {
  opacity: .95;
}

.courseUsage .ui.button.downloadButton {
  margin-left: 15px;
}

.courseUsage .right-section {
  display: flex;
  flex-direction: row;
}

.courseUsage .download-frame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}